<template>
    <main class="product page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
        <section v-if="productData" class="product__top">
            <div class="card gift-product__card">
                <div class="card__wrapper">
                    <h1 class="title title__h1 card__title gift-product__card-title_amazon">
                        <span class="card__span">Giftcard</span>
                    </h1>
                    <div v-if="supplierData" class="card__img-wrapper gift-product__img-wrapper_amazon">
                        <img class="card__img" :src="supplierData.light_logo" alt="">
                    </div>
                    <!-- <div class="card__top-price gift-product__card-price_amazon">
                        <span class="card__span">{{ ' ' + productData.price + ' ' }}</span>
                        <span class="card__span">{{ $t("COMMON.NIS") }}</span>
                    </div> -->
                </div>

                <div class="card__background gift-product__background_color_lightorange" :style="{'background-color': supplierData ? supplierData.chosen_card_background_color : 'initial'}">
                    <img 
                        src="/assets/img/icons/info.svg" 
                        alt="" 
                        class="infor-icon-card"
                        @click="openInforPopup(productData)"
                        v-if="productData.show_additional_details"
                    >   
                    <p class="text card__product-name " :style="{'color': supplierData ? supplierData.font_color : 'initial'}">{{ productData.name }}</p>
                    <p class="product-desc" v-html="productData.description"></p>
                    <div class="gift-product__wrapper">
                        <div class="card__price gift-product__price gift-product__price_color_black">
                            <b class="card__price-value gift-product__price-value">{{ ' ' + productData.price + ' ' }}</b>
                            <b class="card__current gift-product__current">{{ $t("COMMON.NIS") }}</b>
                        </div>
                        <div class="card__product-line gift-product__product-line_dark gift-product__product-line " :style="{'background-color': supplierData ? supplierData.logo_background_color : 'initial'}">
                            <p class="fs20" :style="{'color': supplierData ? supplierData.font_color : 'initial'}">Giftcard</p>
                            <img v-if="supplierData" class="card__line-logo gift-product__line-logo_amazon"
                                :src="supplierData.image" alt="">
                        </div>
                    </div>
                    <p class="fs16 valid-day">{{ $t('COMMUNICATION.VALID_ON_DAYS') }}: {{ productData.usage_time_limit }}</p>
                    <p v-if="!productData.add_vat" class="no-vat-remark">
                        {{ $t('COMMUNICATION.PRODUCT_WITH_NO_VAT') }}
                    </p>
                </div>

                <div class="card__socials m-t-20">

                    <b class="card__share">{{ $t("TOURISM_PRODUCT.SHARE_OVER") }}</b>

                    <div class="card__socials-wrapper">
                        <a class="link card__social" href="">
                            <svg class="category__whatsapp" xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                 viewBox="0 0 30 30">
                                <defs:style>
                                    .whatsapp {
                                        fill: #29abe2;
                                    }
                                </defs:style>
                                <path class="whatsapp"
                                      d="M25.507,36.359A14.871,14.871,0,0,0,2.109,54.3L0,62l7.882-2.069a14.821,14.821,0,0,0,7.1,1.808h.007A14.772,14.772,0,0,0,25.507,36.359ZM14.993,59.234A12.334,12.334,0,0,1,8.7,57.513l-.449-.268L3.576,58.471l1.246-4.56-.295-.469a12.38,12.38,0,1,1,22.962-6.569A12.494,12.494,0,0,1,14.993,59.234ZM21.77,49.98c-.368-.187-2.2-1.085-2.538-1.205s-.589-.187-.837.188-.958,1.205-1.179,1.46-.435.281-.8.094a10.111,10.111,0,0,1-5.056-4.42c-.382-.656.382-.609,1.092-2.029a.688.688,0,0,0-.033-.65c-.094-.187-.837-2.016-1.145-2.759-.3-.723-.609-.623-.837-.636s-.462-.013-.71-.013a1.376,1.376,0,0,0-.991.462,4.174,4.174,0,0,0-1.3,3.1,7.277,7.277,0,0,0,1.513,3.844,16.6,16.6,0,0,0,6.348,5.612c2.357,1.018,3.281,1.1,4.46.931a3.8,3.8,0,0,0,2.5-1.768,3.107,3.107,0,0,0,.214-1.768C22.386,50.254,22.138,50.161,21.77,49.98Z"
                                      transform="translate(0 -32)"/>
                            </svg>
                        </a>

                        <a class="link card__social" href="">
                            <svg class="category__sms" xmlns="http://www.w3.org/2000/svg" width="34" height="30"
                                 viewBox="0 0 34 30">
                                <defs:style>
                                    .sms {
                                        fill: #29abe2;
                                    }
                                </defs:style>
                                <path class="sms"
                                      d="M20.3,41.643H19.224a1.066,1.066,0,0,0-.958.589L17.081,44.6,15.9,42.232a1.066,1.066,0,0,0-.958-.589H13.867A1.075,1.075,0,0,0,12.8,42.714v6.964a.537.537,0,0,0,.536.536H14.4a.537.537,0,0,0,.536-.536V45.112L16.6,48.848a.535.535,0,0,0,.958,0l1.661-3.737v4.567a.537.537,0,0,0,.536.536h1.071a.537.537,0,0,0,.536-.536V42.714A1.065,1.065,0,0,0,20.3,41.643ZM9.005,44.489a.343.343,0,0,1-.141-.254c0-.208.3-.442.7-.442h.824a.537.537,0,0,0,.536-.536V42.185a.537.537,0,0,0-.536-.536H9.568a2.723,2.723,0,0,0-2.839,2.585,2.487,2.487,0,0,0,.891,1.882l1.467,1.259a.343.343,0,0,1,.141.254c0,.208-.3.442-.7.442H7.706a.537.537,0,0,0-.536.536v1.071a.537.537,0,0,0,.536.536H8.53a2.723,2.723,0,0,0,2.839-2.585,2.487,2.487,0,0,0-.891-1.882L9.005,44.489Zm16.071,0a.343.343,0,0,1-.141-.254c0-.208.3-.442.7-.442h.824a.537.537,0,0,0,.536-.536V42.185a.537.537,0,0,0-.536-.536h-.824a2.723,2.723,0,0,0-2.839,2.585,2.487,2.487,0,0,0,.891,1.882l1.467,1.259a.343.343,0,0,1,.141.254c0,.208-.3.442-.7.442h-.817a.537.537,0,0,0-.536.536v1.071a.537.537,0,0,0,.536.536H24.6a2.723,2.723,0,0,0,2.839-2.585,2.487,2.487,0,0,0-.891-1.882ZM17.081,32C7.612,32-.062,38.234-.062,45.929A12.31,12.31,0,0,0,3.48,54.386a16.993,16.993,0,0,1-3.107,4.9A1.608,1.608,0,0,0,1.539,62a16.248,16.248,0,0,0,9.315-3.1,20.759,20.759,0,0,0,6.228.958c9.469,0,17.143-6.234,17.143-13.929S26.55,32,17.081,32Zm0,25.714a18.47,18.47,0,0,1-5.571-.857l-1.018-.321-.871.616A14.449,14.449,0,0,1,2.751,59.8a18.065,18.065,0,0,0,2.732-4.661l.475-1.252-.917-.978a10.143,10.143,0,0,1-2.953-6.984c0-6.5,6.73-11.786,15-11.786s15,5.29,15,11.786S25.351,57.714,17.081,57.714Z"
                                      transform="translate(0.066 -32)"/>
                            </svg>
                        </a>

                        <a class="link card__social" href="">
                            <svg class="category__instagram" xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                 viewBox="0 0 30 30">
                                <defs:style>
                                    .instagram {
                                        fill: #29abe2;
                                    }
                                </defs:style>
                                <path class="instagram"
                                      d="M14.932,39.133a7.692,7.692,0,1,0,7.692,7.692A7.679,7.679,0,0,0,14.932,39.133Zm0,12.692a5,5,0,1,1,5-5,5.01,5.01,0,0,1-5,5Zm9.8-13.007a1.794,1.794,0,1,1-1.794-1.794A1.79,1.79,0,0,1,24.732,38.819Zm5.094,1.821A8.878,8.878,0,0,0,27.4,34.354a8.937,8.937,0,0,0-6.286-2.423c-2.477-.141-9.9-.141-12.378,0a8.924,8.924,0,0,0-6.286,2.417A8.907,8.907,0,0,0,.03,40.633c-.141,2.477-.141,9.9,0,12.378A8.878,8.878,0,0,0,2.454,59.3,8.948,8.948,0,0,0,8.74,61.72c2.477.141,9.9.141,12.378,0A8.878,8.878,0,0,0,27.4,59.3a8.937,8.937,0,0,0,2.423-6.286C29.967,50.534,29.967,43.116,29.826,40.64Zm-3.2,15.028a5.063,5.063,0,0,1-2.852,2.852c-1.975.783-6.661.6-8.843.6s-6.875.174-8.843-.6a5.063,5.063,0,0,1-2.852-2.852c-.783-1.975-.6-6.661-.6-8.843s-.174-6.875.6-8.843A5.063,5.063,0,0,1,6.089,35.13c1.975-.783,6.661-.6,8.843-.6s6.875-.174,8.843.6a5.063,5.063,0,0,1,2.852,2.852c.783,1.975.6,6.661.6,8.843S27.41,53.7,26.626,55.668Z"
                                      transform="translate(0.075 -31.825)"/>
                            </svg>
                        </a>

                        <a class="link card__social" href="">
                            <svg class="category__facebook" xmlns="http://www.w3.org/2000/svg" width="16" height="30"
                                 viewBox="0 0 16 30">
                                <defs:style>
                                    .facebook {
                                        fill: #29abe2;
                                    }
                                </defs:style>
                                <path class="facebook"
                                      d="M37.9,16.875l.833-5.429h-5.21V7.922a2.715,2.715,0,0,1,3.061-2.933h2.368V.367A28.882,28.882,0,0,0,34.753,0c-4.29,0-7.095,2.6-7.095,7.308v4.138H22.89v5.429h4.769V30h5.869V16.875Z"
                                      transform="translate(-22.89)"/>
                            </svg>
                        </a>
                    </div>

                </div>
            </div>

            <div class="confirm-phone product__confirm-phone">
                <div class="confirm-phone__container">
                    <!-- <div class="confirm-phone__header">
                        <div class="logo confirm-phone__logo">
                            <a class="link" href="index.html">
                                <img class="img" :src="currentLogo" alt="">
                            </a>
                        </div>
                        <button class="button confirm-phone__close">
                            <img src="/assets/img/close.svg" alt="">
                        </button>
                    </div> -->

                    <div class="confirm-phone__wrapper">

                        <div class="confirm-phone__title">
                            <span class="confirm-phone__price product__confirm-phone-phone-price.price">{{ $t("COMMON.PRICE") }}:</span>
                            <b class="confirm-phone__price-value product__confirm-phone-phone-price.value">{{ ' ' + productData.price + ' ' }}</b>
                            <b class="confirm-phone__current product__confirm-phone-phone-price.current">{{ $t("COMMON.NIS") }}</b>
                        </div>

                        <form class="confirm-phone__form" action="">

                            <div class="confirm-phone__enter">
                                <label class="confirm-phone__label" for="phone_number">
                                    {{ $t("GIFTCARD_PRODUCT.PHONE_NUMBER") }}
                                </label>
                                <input id="phone_number"
                                       class="input confirm-phone__input"
                                       type="tel"
                                       v-model="payment.phone_number"
                                       v-model.lazy="$v.payment.phone_number.$model"
                                       :class="{ 'validation-error': $v.payment.phone_number.$error }">
                            </div>

                            <!-- <div class="confirm-phone__repeat">
                                <label class="confirm-phone__label" for="phone_number_confirm">
                                    {{ $t("GIFTCARD_PRODUCT.PHONE_VERIFY") }}
                                </label>
                                <input id="phone_number_confirm"
                                       class="input confirm-phone__input"
                                       type="tel"
                                       v-model="payment.phone_number_confirm"
                                       v-model.lazy="$v.payment.phone_number_confirm.$model"
                                       :class="{ 'validation-error': $v.payment.phone_number_confirm.$error }">
                            </div> -->

                        </form>
                        <a id="payment_submit" @click="openConfirmModal" class="link confirm-phone__button">{{ $t("COMMON.TIME") }}</a>
                    </div>
                    <!-- <button class="button confirm-phone__button confirm-phone__button-2">{{ $t("COMMON.TIME") }}</button> -->
                </div>

            </div>
        </section>
        <section class="m-t-40" v-if="supplierData && expensiveProduct && expensiveProduct.length">
            <h2 class="title title__h2 product__title m-b-10">{{ $t('SUPPLIER_PAYMENT.UPGRADE_TO') }}</h2>
            <div class="d-flex">
                <div class="card gift-product__card before-none pointer" @click="goUpgradeProduct">
                    <div class="card__background gift-product__background_color_lightorange" :style="{'background-color': supplierData ? supplierData.chosen_card_background_color : 'initial'}">
                        <img 
                            src="/assets/img/icons/info.svg" 
                            alt="" 
                            class="infor-icon-card"
                            @click="openInforPopup(supplierData)"
                            v-if="supplierData.show_additional_details"
                        >   
                        <p class="text card__product-name " :style="{'color': supplierData ? supplierData.font_color : 'initial'}">{{ expensiveProduct[0].name }}</p>
                        <p class="product-desc" v-html="expensiveProduct[0].description"></p>
                        <div class="gift-product__wrapper">
                            <div class="card__price gift-product__price gift-product__price_color_black">
                                <b class="card__price-value gift-product__price-value">{{ ' ' + expensiveProduct[0].price + ' ' }}</b>
                                <b class="card__current gift-product__current">{{ $t("COMMON.NIS") }}</b>
                            </div>
                            <div class="card__product-line gift-product__product-line_dark gift-product__product-line " :style="{'background-color': supplierData ? supplierData.logo_background_color : 'initial'}">
                                <p class="fs20" :style="{'color': supplierData ? supplierData.font_color : 'initial'}">Giftcard</p>
                                <img v-if="supplierData" class="card__line-logo gift-product__line-logo_amazon"
                                    :src="supplierData.image" alt="">
                            </div>
                        </div>
                        <p class="fs16 valid-day">{{ $t('COMMUNICATION.VALID_ON_DAYS') }}: {{ productData.usage_time_limit }}</p>
                        <p v-if="!productData.add_vat" class="no-vat-remark">
                            {{ $t('COMMUNICATION.PRODUCT_WITH_NO_VAT') }}
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section class="product__info" v-if="productDetailsText || upscaleProductData">

            <p v-if="productDetailsText" v-html="productDetailsText" class="text product__text"></p>

            <div v-if="upscaleProductData" class="product__more gift-product__more gift-product__more_bg_color_orange" :style="{'background-color': supplierData ? supplierData.product_card_background_color : 'initial'}">

                <div class="product__more-wrapper">
                    <div class="product__refresh">
                        <span class="product__span">{{ upscaleProductData.name }}</span>
                        <span class="product__span">{{ ' ' + upscaleProductData.price + ' ' }}</span>
                        <span class="product__span">{{ $t("COMMON.NIS") }}</span>
                    </div>

                    <div class="product__new-price">
                        <span class="product__span">{{ upscaleProductData.name }}</span>
                        <span class="product__span">{{ ' ' + upscaleProductData.price + ' ' }}</span>
                        <span class="product__span">{{ $t("COMMON.NIS") }}</span>

                    </div>

                    <router-link :to="{name: 'GiftCardProduct', params: {id: upscaleProductData.id }}" tag="a" class="link product__details gift-product__details" exact>{{ $t("GIFTCARD_PRODUCT.MORE_DETAILS") }}</router-link>
                </div>
                <div v-if="supplierData" class="product__more-img">
                    <img class="product__more-cover gift-product__more-cover_amazon" :src="supplierData.image"
                         alt="">
                </div>

            </div>

        </section>

        <section class="product__similar" v-if="supplierData && expensiveProduct && expensiveProduct.length">

            <h2 class="title title__h2 product__title">{{ $t("GIFTCARD_PRODUCT.SIMILAR_PRODUCTS") }}</h2>

            <div class="product__wrapper">
                <div v-for="(product_item, index) in expensiveProduct" :key="index" class="category__unit ">

                    <div class="category__bg gift-category__unit-bg_color_orange" :style="{'background-color': supplierData ? supplierData.product_card_background_color : 'initial'}">

                        <p v-if="!product_item.add_vat" class="cat-unit-no-vat-remark">
                            {{ $t('COMMUNICATION.PRODUCT_WITH_NO_VAT') }}
                        </p>

                        <CategoryShare></CategoryShare>

                        <div class="category__star" @click="changeFavorite(product_item.id, !product_item.favorite)">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="25"
                                viewBox="0 0 26 25"
                            >
                                <path
                                    :style="{ fill: product_item.favorite ? supplierData.favorite_star_color : '#fff'}"
                                    d="M32.158.857,28.97,7.321l-7.133,1.04a1.563,1.563,0,0,0-.864,2.666l5.161,5.029-1.221,7.1A1.561,1.561,0,0,0,27.178,24.8l6.381-3.354L39.941,24.8a1.563,1.563,0,0,0,2.265-1.645l-1.221-7.1,5.16-5.029a1.563,1.563,0,0,0-.864-2.666l-7.133-1.04L34.961.857a1.564,1.564,0,0,0-2.8,0Z"
                                    transform="translate(-20.5 0.013)"
                                />
                            </svg>
                        </div>

                        <img 
                            src="/assets/img/icons/info.svg" 
                            alt="" 
                            class="infor-icon"
                            @click="openInforPopup(product_item)"
                            v-if="product_item.show_additional_details"
                        >   

                        <p class="gift-category__product-name" :style="{'color': supplierData ? supplierData.font_color : 'initial'}">{{ product_item.name }}</p>
                        <div class="gift-category__unit-caption">
                            <!-- <div class="gift-category__price gift-category__price_color_black-2">
                                <b class="gift-category__price-value">{{ ' ' + product_item.price + ' ' }}</b>
                                <b class="gift-category__current">{{ $t("COMMON.NIS") }}</b>
                            </div> -->
                            <div class="gift-category__product-line gift-category__product-line_color_dark" :style="{'background-color': supplierData ? supplierData.logo_background_color : 'initial'}">
                                <p class="fs20" :style="{'color': supplierData ? supplierData.font_color : 'initial'}">Giftcard</p>
                                <div v-if="supplierData" class="gift-category__img-wrapper">
                                    <img class="gift-category__img" :src="supplierData.image" alt="">
                                </div>
                            </div>
                        </div>
                        <p class="fs16 valid-day">{{ $t('COMMUNICATION.VALID_ON_DAYS') }}: {{ productData.usage_time_limit }}</p>
                    </div>

                    <div class="category__info">
                        <p class="text category__text" v-html="product_item.description"></p>
                        <div class="category__price-wrapper ">
                            <span class="category__price">{{ $t("COMMON.PRICE") }}:</span>
                            <b class="category__price-value ">{{ ' ' + product_item.price + ' ' }}</b>
                            <b class="category__current ">{{ $t("COMMON.NIS") }}</b>
                        </div>
                        <router-link :to="{name: 'GiftCardProduct', params: {id: product_item.id, supplier_id: product_item.supplier_id }}" tag="a" class="link category__button" exact>{{ $t("COMMON.TIME") }}</router-link>
                    </div>
                </div>
            </div>

        </section>
<!-- 
        <div v-if="productData" class="product__panel">
            <div class="product__panel-wrapper">
                <h2 class="title title__h2 product__title-bottom">{{ ' ' + productData.price + ' ' }}</h2>
                <span class="product__current">{{ $t("COMMON.NIS") }}</span>
            </div>
            <button class="button product__button">{{ $t("COMMON.TIME") }}</button>
        </div> -->

        <section id="payment-verification-code-modal" class="modal-default" :class="{'modal-default_active': paymentVerificationCodeModal.active}">
            <div class="modal-default__container">

                <button class="button modal-default__close" @click="cancelTransactionHandler">
                    <img src="/assets/img/close.svg" alt="">
                </button>

                <h2 class="title title__h2 modal-default__title">
                    {{ $t("GIFTCARD_PRODUCT.VERIFY_MODAL_TITLE") }}
                </h2>

                <p class="text modal-default__text">
                    {{ $t("GIFTCARD_PRODUCT.VERIFY_MODAL_TEXT") }}
                </p>

                <div v-if="paymentVerificationCodeModal.verification_code" class="verification-code-text text modal-default__text">
                    <div>{{ $t("GIFTCARD_PRODUCT.ONE_VCODE") }}</div>
                    <div class="verification-code">{{ paymentVerificationCodeModal.verification_code }}</div>
                </div>

                <button class="button modal-default__confirm" @click="approveTransactionHandler">
                    {{ $t("GIFTCARD_PRODUCT.APPROVAL") }}
                </button>

                <button class="button cancel-button modal-default__cancel" @click="cancelTransactionHandler">
                    {{ $t("GIFTCARD_PRODUCT.CANCEL_RESERVATION") }}
                </button>

            </div>
        </section>

        <loading :active.sync="loading"
            :can-cancel="false"
            :is-full-page="true">
        </loading>
        <modal :width="600" :adaptive="true" name="ConfirmModal" class="CEModal modal-scroll-bar">
            <div class="modal-content modal-default__container text-center">
                <h2 class="title title__h2 modal-default__title">
                    {{
                    $t('COMMUNICATION_PRODUCT.CONFIRMATION_PHONE_NUMBER_MODAL_TITLE')
                    }}
                </h2>

                <p class="text modal-default__text"
                    v-html="
                        $t(
                        'COMMUNICATION_PRODUCT.CONFIRMATION_PHONE_NUMBER_MODAL_TEXT',
                        ).indexOf('[phone_number]') !== -1
                        ? $t(
                            'COMMUNICATION_PRODUCT.CONFIRMATION_PHONE_NUMBER_MODAL_TEXT',
                            ).replace('[phone_number]', formattedPhoneNumber)
                        : $t(
                            'COMMUNICATION_PRODUCT.CONFIRMATION_PHONE_NUMBER_MODAL_TEXT',
                            ) +
                            ' ' +
                            formattedPhoneNumber
                    "
                ></p>
                <button class="button modal-default__confirm" @click="handlePaymentSubmit()">{{ $t("COMMON.OK") }}</button>
                <button class="button cancel-button modal-default__cancel" @click="closeConfirmModal()">{{ $t("COMMON.CANCEL") }}</button>
            </div>
        </modal>
        <InforPopup :inforData="inforData" :modal_key="modal_key"></InforPopup>
    </main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import appConfig from '../../appConfig'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators'

import CategoryShare from '../../components/communication/CategoryShare.vue'
import InforPopup from '../../components/communication/InforPopup.vue'
export default {
    components: {
        Loading,
        CategoryShare,
        InforPopup
    },
    mixins: [validationMixin],
    name: 'GiftCardProduct',
    data: () => {
        return {
            payment: {
                phone_number: '',
                // phone_number_confirm: ''
            },
            paymentVerificationCodeModal: {
                active: false,
                verification_code: null,
                transaction_id: null
            },
            loading: false,
            modal_key: +new Date(),
            inforData: ""
        }
    },
    validations: {
        payment: {
            phone_number: {
                required,
                minLength: minLength(10),
                maxLength: maxLength(10)
            },
            // phone_number_confirm: {
            //     required,
            //     minLength: minLength(10),
            //     maxLength: maxLength(10),
            //     sameAsPhoneNumber: sameAs('phone_number')
            // },
        }
    },
    computed: {
        ...mapState({
            supplier_items: state => state.supplier.supplier_items,
            suppliers: state => state.supplier.suppliers,
            suppliers_products: state => state.supplier.suppliers_products,
            products: state => state.product.products,
            giftCardPaymentRequestInProgress: state => state.gift_card_payment.giftCardPaymentRequestInProgress
        }),
        productId() {
            return this.$route.params.id
        },
        supplierId() {
            return this.$route.params.supplier_id
        },
        upscaleProductId() {
            return this.productData ? this.productData.upscale_product_id : null
        },
        upscaleProductSupplierId() {
            return this.upscaleProductData ? this.upscaleProductData.supplier_id : null
        },
        productData() {
            let productDataFromSuppliersData = null;
            
            if (this.suppliers_products) {
                for (let supplier_id in this.suppliers_products) {
                    for (let supplier_product_type in this.suppliers_products[supplier_id]) {
                        for (let supplier_product of this.suppliers_products[supplier_id][supplier_product_type]) {
                            if (supplier_product.hasOwnProperty('id')
                                && supplier_product.id == this.productId) {
                                productDataFromSuppliersData = {...supplier_product}
                                break;
                            }
                        }
                    }
                }
            }

            let productDataFromProductsData = null;
            if (this.products && this.productId in this.products) {
                productDataFromProductsData = {...this.products[this.productId]}
            }

            let productData = productDataFromProductsData ? productDataFromProductsData : productDataFromSuppliersData
            return productData ? productData : null
        },
        productDetailsText() {
            if (!this.productData || !this.supplierData || this.supplierData.type != 1) {
                return ''
            }

            let productDetails = ''
            let productDetailsRowTemplate = '<p>[row_text]</p>'
            if (this.productData.hasOwnProperty('call_minute') && this.productData.call_minute != '' && this.productData.call_minute != null) {
                productDetails += productDetailsRowTemplate.replace('[row_text]', 'דקות שיחה:' + ' ' + this.productData.call_minute)
            }
            if (this.productData.hasOwnProperty('sms') && this.productData.sms != '' && this.productData.sms != null) {
                productDetails += productDetailsRowTemplate.replace('[row_text]', 'הודעות:' + ' ' + this.productData.sms)
            }
            if (this.productData.hasOwnProperty('browsing_package') && this.productData.browsing_package != '' && this.productData.browsing_package != null) {
                productDetails += productDetailsRowTemplate.replace('[row_text]', 'נפח גלישה:' + ' ' + this.productData.browsing_package)
            }
            if (this.productData.hasOwnProperty('usage_time_limit') && this.productData.usage_time_limit != '' && this.productData.usage_time_limit != null) {
                productDetails += productDetailsRowTemplate.replace('[row_text]', 'תוקף בימים:' + ' ' + this.productData.usage_time_limit)
            }
            if (this.productData.hasOwnProperty('call_terms') && this.productData.call_terms != '' && this.productData.call_terms != null) {
                productDetails += productDetailsRowTemplate.replace('[row_text]', 'תנאי שיחות:' + ' ' + this.productData.call_terms)
            }
            if (this.productData.hasOwnProperty('call_to_palestinian') && this.productData.call_to_palestinian != false && this.productData.call_to_palestinian != null) {
                productDetails += productDetailsRowTemplate.replace('[row_text]', 'שיחות לרשות:' + ' ' + '✓')
            }
            if (this.productData.hasOwnProperty('abroed_price') && this.productData.abroed_price != '' && this.productData.abroed_price != null) {
                productDetails += productDetailsRowTemplate.replace('[row_text]', 'מחיר לשימוש בחו\'\'ל ובארץ:' + ' ' + this.productData.abroed_price)
            }
            if (this.productData.hasOwnProperty('other1') && this.productData.other1 != '' && this.productData.other1 != null) {
                productDetails += productDetailsRowTemplate.replace('[row_text]', 'אחר 1:' + ' ' + this.productData.other1)
            }
            if (this.productData.hasOwnProperty('other2') && this.productData.other2 != '' && this.productData.other2 != null) {
                productDetails += productDetailsRowTemplate.replace('[row_text]', 'אחר 2:' + ' ' + this.productData.other2)
            }

            return productDetails
        },
        supplierData() {
            let supplierDataFromSuppliersItemsData = null;
            if (this.supplier_items) {
                for (let supplier_type in this.supplier_items) {
                    for (let suppliers_type_item of this.supplier_items[supplier_type]) {
                        if (suppliers_type_item.id == this.supplierId) {
                            supplierDataFromSuppliersItemsData = {...suppliers_type_item};
                            break;
                        }
                    }
                }
            }

            let supplierDataFromSuppliersData = null;
            if (this.suppliers && this.supplierId in this.suppliers) {
                supplierDataFromSuppliersData = {...this.suppliers[this.supplierId]}
            }

            let supplierData = supplierDataFromSuppliersData ? supplierDataFromSuppliersData : supplierDataFromSuppliersItemsData
            return supplierData ? supplierData : null
        },
        upscaleProductData() {
            let upscaleProductDataFromSuppliersData = null;

            if (this.suppliers_products) {
                for (let supplier_id in this.suppliers_products) {
                    for (let supplier_product_type in this.suppliers_products[supplier_id]) {
                        for (let supplier_product of this.suppliers_products[supplier_id][supplier_product_type]) {
                            if (supplier_product.hasOwnProperty('id')
                                && supplier_product.id == this.upscaleProductId) {
                                upscaleProductDataFromSuppliersData = {...supplier_product}
                                break;
                            }
                        }
                    }
                }
            }

            let upscaleProductDataFromProductsData = null;
            if (this.products && this.upscaleProductId in this.products) {
                upscaleProductDataFromProductsData = {...this.products[this.upscaleProductId]}
            }

            let upscaleProductData = upscaleProductDataFromProductsData ? upscaleProductDataFromProductsData : upscaleProductDataFromSuppliersData
            return upscaleProductData ? upscaleProductData : null
        },
        upscaleProductSupplierData() {
            let upscaleProductSupplierDataFromSuppliersItemsData = null;
            if (this.supplier_items) {
                for (let supplier_type in this.supplier_items) {
                    for (let suppliers_type_item of this.supplier_items[supplier_type]) {
                        if (suppliers_type_item.id == this.upscaleProductSupplierId) {
                            upscaleProductSupplierDataFromSuppliersItemsData = {...suppliers_type_item};
                            break;
                        }
                    }
                }
            }

            let upscaleProductSupplierDataFromSuppliersData = null;
            if (this.suppliers && this.upscaleProductSupplierId in this.suppliers) {
                upscaleProductSupplierDataFromSuppliersData = {...this.suppliers[this.upscaleProductSupplierId]}
            }

            let upscaleProductSupplierData = upscaleProductSupplierDataFromSuppliersData ? upscaleProductSupplierDataFromSuppliersData : upscaleProductSupplierDataFromSuppliersItemsData
            return upscaleProductSupplierData ? upscaleProductSupplierData : null
        },
        supplierProductsAll() {
            let supplierProducts = [];
            if (
                this.suppliers_products &&
                this.supplierId in this.suppliers_products
            ) {
                let supplerProductsByType = this.suppliers_products[this.supplierId];

                if (supplerProductsByType) {
                for (let supplier_product_type in supplerProductsByType) {
                    for (let supplier_product of supplerProductsByType[
                    supplier_product_type
                    ]) {
                    supplierProducts.push(supplier_product);
                    }
                }
                }
            }
            supplierProducts.sort((a,b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0)); 
            return supplierProducts ? supplierProducts : [];
        },
        expensiveProduct() {
            if (this.productData && this.supplierProductsAll && this.supplierProductsAll.length) {
                let expensiveArray = [];
                for (let product of this.supplierProductsAll) {
                if (product && product.price > this.productData.price) {
                    expensiveArray.push(product);
                    if (expensiveArray.length == 4) {
                    break;
                    }
                }
                }
                return expensiveArray;
            }
        },
    },
    async created() {
        this.getProduct({ product_id: this.productId });
        this.getSupplier({ supplier_id: this.supplierId });

        if (this.productData && this.productData.upscale_product_id) {
            this.getProduct({'product_id': this.productData.upscale_product_id})
        }

        if (this.upscaleProductData && this.upscaleProductData.supplier_id && this.supplierId
            && this.upscaleProductData.supplier_id != this.supplierId) {
            this.getSupplier({'supplier_id': this.upscaleProductData.supplier_id})
        }
        if (!this.suppliers_products || this.suppliers_products.length == 0) {
            await this.getSupplierProducts({ supplier_id: this.supplierId });
        }
    },
    watch: {
        upscaleProductId(upscaleProductId) {
            if (!upscaleProductId) {
                return
            }
            this.getProduct({'product_id': upscaleProductId})
        },
        upscaleProductSupplierId(upscaleProductSupplierId) {
            if (!upscaleProductSupplierId) {
                return
            }
            this.getSupplier({'supplier_id': upscaleProductSupplierId})
        },
        giftCardPaymentRequestInProgress(inProgress) {
            this.loading = inProgress;
        }
    },
    methods: {
        ...mapActions('product', {
            getProduct: 'getProduct'
        }),
        ...mapActions('supplier', {
            getSupplier: 'getSupplier',
            getSupplierProducts: 'getSupplierProducts',
            setFavoriteProduct: 'setFavoriteProduct'
        }),
        ...mapActions('gift_card_payment', {
            giftCardPaymentRequest: 'giftCardPaymentRequest',
            cancelTransactionRequest: 'cancelTransactionRequest'
        }),
        getApiURL() {
            return appConfig.apiUrl
        },
        formattedPhoneNumber() {
            let formattedPhoneNumber = this.payment.phone_number;

            if (this.payment.phone_number.length > 3) {
                formattedPhoneNumber =
                    this.payment.phone_number.substr(0, 3) +
                    '-' +
                    this.payment.phone_number.substr(3);
            }

            formattedPhoneNumber = '<b>' + formattedPhoneNumber + '</b>';

            return formattedPhoneNumber;
        },
        handlePaymentSubmit() {
            let obj = this
            this.closeConfirmModal();
            const phone_number = obj.payment.phone_number
            const supplier_id = obj.supplierId
            const product_id = obj.productId
            if (supplier_id && product_id && phone_number) {
                obj.giftCardPaymentRequest({
                    supplier_id: supplier_id,
                    payment_data: {
                        itemId: product_id,
                        phoneNumber: phone_number
                    }
                })
                    .then(
                        result_data => {
                            if (result_data && result_data.hasOwnProperty('currentTransaction') && result_data.currentTransaction
                                && result_data.hasOwnProperty('success') && result_data.success) {
                                let success_data = result_data.success
                                if ('verificationCode' in success_data) {
                                    this.openPaymentVerificationCodeModal({
                                        verification_code: success_data.verificationCode,
                                        transaction_id: result_data.currentTransaction
                                    })
                                }
                            }

                        },
                        error => {
                        }
                    );
            }
        },
        openPaymentVerificationCodeModal(modal_data) {
            this.paymentVerificationCodeModal.verification_code = modal_data.verification_code
            this.paymentVerificationCodeModal.transaction_id = modal_data.transaction_id
            this.paymentVerificationCodeModal.active = true
            $('html').css("overflow", "hidden");
        },
        closePaymentCodeModal() {
            this.paymentVerificationCodeModal.verification_code = null
            this.paymentVerificationCodeModal.transaction_id = null
            this.paymentVerificationCodeModal.active = false
            $('html').css("overflow", "auto");
        },
        cancelTransactionHandler() {
            let currentPaymentVerificationCodeModalData = {...this.paymentVerificationCodeModal}

            this.closePaymentCodeModal()

            if (currentPaymentVerificationCodeModalData.transaction_id) {
                this.cancelTransactionRequest({'transaction_id': currentPaymentVerificationCodeModalData.transaction_id})
            }
        },
        approveTransactionHandler() {
            let currentPaymentVerificationCodeModalData = {...this.paymentVerificationCodeModal}

            this.closePaymentCodeModal()

            if (currentPaymentVerificationCodeModalData.transaction_id) {
                this.redirectToPaymentResult(currentPaymentVerificationCodeModalData.transaction_id)
            }
        },
        redirectToPaymentResult(transaction_id) {
            this.$router.push({name: 'GiftCardPaymentResult', params: {'transaction_id': transaction_id}})
        },

        /* confirm phone number modal */
        openConfirmModal() {
            let obj = this
            obj.$v.$touch();
            if (obj.$v.payment.phone_number.$invalid) {
                return;
            }
            this.$modal.show("ConfirmModal");
        },
        closeConfirmModal() {
            this.$modal.hide("ConfirmModal");
        },
        /* confirm phone number modal end */
        goUpgradeProduct() {
            this.$router.push({
                name: 'GiftCardProduct', 
                params: {
                    id: this.expensiveProduct[0].id,
                    supplier_id: this.expensiveProduct[0].supplier_id
                }
            })
        },
        changeFavorite(id, value) {
            this.setFavoriteProduct({product_id: id, supplier_id: this.supplierId, favorite: value});
        },
        /* <!-- ------------information popup-------------- --> */
        openInforPopup(item) {
            if (item.additional_details=="" || !item.additional_details) {
                this.inforData = item.additional_details_default;
            } else {
                this.inforData = item.additional_details;
            }
            this.$modal.show("inforPopup_"+this.modal_key);
        }, 
    },
}
</script>

<style>
    .gift-product__background::before,
    .gift-product__more::before {
        content: none;
        display: none;
    }
    .gift-product__gift-logo-wrapper {
        width: 85px;
        height: 200px;
        position: absolute;
        top: calc(50% - 100px);
        left: 0;
        line-height: 200px;
        border-top-right-radius: 35px;
        border-bottom-right-radius: 35px;
    }
    .gift-product__more-logo-wrapper {
        width: 60px;
        height: 140px;
        position: absolute;
        top: calc(50% - 70px);
        left: 0;
        line-height: 140px;
        border-top-right-radius: 35px;
        border-bottom-right-radius: 35px;
    }
    .gift-product__gift-logo-wrapper img,
    .gift-product__more-logo-wrapper img {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        max-height: 55px;
        vertical-align: middle;
    }

    .confirm-phone__price {
        color: #c92d4c;
        font-size: 26px;
    }
    .confirm-phone__price-value {
        color: #c92d4c;
        font-size: 40px;
    }
    .confirm-phone__current {
        color: #c92d4c;
        font-size: 40px;
    }

    .verification-code-text {
        margin-top: 20px;
    }
    .verification-code {
        margin-top: 8px;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #C92D4C;
        opacity: 1;
    }
    .cancel-button {
        font-size: 18px;
        font-weight: normal;
        letter-spacing: 0px;
        color: #C92D4C;
        opacity: 1;
    }

</style>
<style lang="scss" scoped>
    .cat-unit-no-vat-remark {
        position: absolute;
        top: 25px;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: bold;
        font-size: 24px;
        color: #fff;
    }
    .no-vat-remark {
        position: absolute;
        top: 35px;
        font-size: 24px;
        font-weight: bold;
        left: 20px;
        color: #fff;
    }
    .card__product-name {
        padding: 0 12px 0 40px;
        position: absolute;
        top: 8px;
        font-size: 26px;
        font-weight: bold;
    }
    .valid-day {
        position: absolute;
        bottom: 3px;
        padding: 0 5px;
        color: #fff;
    }
    .gift-product__wrapper {
        bottom: 24px;
        .gift-product__price-value {
            font-size: 28px;
        }
        .gift-product__current {
            font-size: 20px;
        }
    }
    .product-desc {
        position: absolute;
        top: 38px;
        color: #fff;
        max-width: 90%;
        height: 24px;
        padding: 0 12px;
        font-size: 20px;
        font-weight: bold;
    }
    .gift-category__img-wrapper {
        display: flex;
        align-items: center;
    }
    .gift-category__img {
        max-width: 80%;
    }
    .gift-category__product-name {
        padding: 0 12px;
        position: absolute;
        top: 45px;
        font-size: 24px;
        font-weight: bold;
    }
    .gift-category__unit-caption {
        bottom: 24px;
    }
    .category__text {
        max-width: 90%;
        height: 24px;
    }
    .product__similar {
        position: relative;
        margin-top: 60px;
        &::before {
            content: "";
            width: 100%;
            height: 1px;
            background: #b2bbc2;
            position: absolute;
            top: -30px;
            left: 0;
        }
    }

    .infor-icon-card {
        position: absolute;
        top: 10px;
        left: 12px;
        cursor: pointer;
        width: 20px;
        height: 20px;
        z-index: 2;
    }
    .infor-icon {
        position: absolute;
        top: 13px;
        left: 55px;
        cursor: pointer;
        width: 24px;
        height: 24px;
    }

    @media screen and (max-width: 1365px) {
        .product__top {
            flex-wrap: wrap;
            .confirm-phone {
                margin-top: 0;
                position: initial;
                opacity: 1;
                visibility: initial;
                flex-basis: 100%;
                z-index: unset;
            }
            .card {
                max-width: 100%;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .no-vat-remark {
            top: 83px;
        }
        .gift-product__product-line {
            line-height: 22px;
        }
        .card__text {
            margin-top: 100px;
        }
        .product__similar {
            margin-top: 40px;
        }
        .product__title {
            font-size: 24px;
            margin-bottom: 20px;
            text-align: center;
        }
        .card__background {
            height: 230px;
        }
        .card__wrapper {
            position: initial;
        }
        .card__img-wrapper {
            display: block;
        }
    }
    .ltr-type {
        .no-vat-remark {
            left: unset;
            right: 20px;
        }
        .card__product-name {
            padding: 0 40px 0 12px;
        }
        .gift-product__price {
            margin-left: 22px;
        }
        .gift-product__product-line {
            padding-left: 22px;
        }
        .card__share {
            margin-left: 0;
            margin-right: 25px;
        }
        .category__unit:not(:nth-child(4n)) {
            margin-left: 0;
            margin-right: 15px;
        }
        .gift-category__price {
            margin-left: 20px;
        }
        .gift-category__product-line{
            padding-right: 0;
            padding-left: 20px;
        }
        .infor-icon-card {
            right: 12px;
            left: unset;
        }

    }
</style>